<template>
  <div class="container">
    <div class="header">
      <div class="header-content">
        <!-- nav-left -->
        <div class="header-left">
          <div class="total-menu"><img @click="visibleCommonMenu=!visibleCommonMenu" src="../assets/menu.png" alt=""></div>
          <div><img @click="toHome" style="width:70px;height:24px" src="../assets/logo.png" alt=""></div>
        </div>
        <!-- nav-right -->
        <div>
          <ul class="header-right">
            <!-- <li class="relative">
              <a-select
                show-search
                placeholder="搜索菜单"
                style="width: 200px"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                @search="handleSearch"
                @change="handleChange"
              >
                <a-select-option v-for="d in searchList" :key="d.value">
                  {{ d.text }}
                </a-select-option>
              </a-select>
              <div class="search-icon"><a-icon type="search" style="font-size:20px;color:#DEDEDE" /></div>
            </li> -->
            <li v-for="(item,index) in commonMenu" :key="index">
              <a class="single" @click="ggMenu(item.funUrl)" :href="item.funUrl" target="_blink" v-if="item.funType==='10'&&item.showType==='20'&&item.status==='100201'">{{item.funName}}</a>
              <a class="single" @click="ggMenu(item.funUrl)" :href="item.funUrl" target="_blink" v-if="item.funType==='10'&&item.showType==='10'&&item.status==='100201'">
                <img style="width:14px;height:14px" :src="item.funLogo" alt="">
              </a>
              <a-dropdown v-if="item.funType==='20'&&item.status==='100201'">
                <a class="ant-dropdown-link main-name">
                  {{item.funName}}
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-for="(list,indexs) in item.items" :key="indexs">
                    <a @click="ggMenu(item.itemUrl)" :href="list.itemUrl" target="_blink" v-if="list.status==='100201'">{{list.itemName}}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </li>
            <li class="user-info">
              <a-dropdown>
                <a class="ant-dropdown-link" v-if="userInfo">
                  <div class="header-right">
                    <div class="header-right-title">{{userInfo.nikeName.slice(0,1)}}</div>
                    <div class="header-right-name">{{userInfo.nikeName}}</div>
                  </div>
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" @click="logout">退出</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="welcome_bg" v-if="isHome">

    </div> -->
    <div class="content">
      <!-- 公用菜单 -->
      <a-drawer
        title="产品总览"
        :placement="placement"
        :closable="false"
        :visible="visibleCommonMenu"
        @close="closeCommonMenu"
        :wrap-style="{ position: 'absolute' }"
        :header-style="{textAlign:'center',background:'#F7F9FA',color:'#333'}"
        :get-container="false"
        width="220px"
      >
        <ul class="commom-menu">
          <li v-for="(item,index) in productionMenu" :key="index">
            <a @click="ggMenu(item.productUrl)" :href="item.productUrl" target="_self" v-if="item.status==='100201'">
              <img :src="item.productLogo" class="img" alt="">
              <span>{{item.productName}}</span>
            </a>
          </li>
        </ul>
      </a-drawer>
      <!-- 项目菜单 -->
      <div class="left" v-if="this.$store.state.user.isSlide">
        <div class="left-title">渠道管理</div>
        <a-menu mode="inline" :open-keys="openKeys" style="width: 250px" @click="selectIt">
          <a-menu-item :class="active==='raskManage'||active==='/raskAdd'||active==='/raskDetail'||active==='/raskDetailMany'?'ant-menu-item-selected':''" key="raskManage">
            <img src="../assets/icon/enterprise_selected.png" class="img" v-if="active==='raskManage'||active==='/raskManage'||active==='/raskAdd'||active==='/raskDetail'||active==='/raskDetailMany'" alt=""><img src="../assets/icon/enterprise.png" class="img" alt="" v-else> <span style="margin-left:-3px">企业管理</span>
          </a-menu-item>
          <!-- <a-menu-item :class="active==='tradingCenter'||active==='/tradingCenter'?'ant-menu-item-selected':''" key="tradingCenter">
            <img src="../assets/icon/enterprise_selected.png" class="img" v-if="active==='tradingCenter'||active==='/tradingCenter'?'ant-menu-item-selected':''" alt=""><img src="../assets/icon/transaction.png" class="img" alt="" v-else> <span style="margin-left:-3px">交易管理</span>
          </a-menu-item> -->
          <a-menu-item :class="active==='taskCenter'||active==='/taskCenter' || active === 'addTask' || active === 'taskDetail' || active === 'payDetail'? 'ant-menu-item-selected':''" key="taskCenter">
            <img src="../assets/icon/task_selected.png" class="img" v-if="active==='taskCenter'||active==='/taskCenter' || active === 'addTask' || active === 'taskDetail' || active === 'payDetail'" alt=""><img src="../assets/icon/task.png" class="img" alt="" v-else> <span style="margin-left:-3px">任务管理</span>
          </a-menu-item>
          <!-- <a-menu-item :class="active==='financeManage'||active==='/financeManage'?'ant-menu-item-selected':''" key="financeManage">
            <img src="../assets/raskManage.png" class="img" alt=""> <span style="margin-left:-3px">财务管理</span>
          </a-menu-item> -->
          <a-sub-menu key="sub2">
            <span slot="title"><span> <img src="../assets/icon/finance_selected.png" class="img" v-if="active==='rechargeOrder'||active==='settlement' " alt=""><img src="../assets/icon/finance.png" class="img" alt="" v-else>财务管理</span></span>
            <a-menu-item key="settlement" :class="active==='settlement'||active==='/settlement' || active === 'settlementDetail' || active === '/settlementDetail'?'ant-menu-item-selected':''">
              审核付款
            </a-menu-item>
            <a-menu-item key="rechargeOrder" :class="active==='rechargeOrder'||active==='/rechargeOrder'?'ant-menu-item-selected':''">
              充值订单
            </a-menu-item>
            <!-- <a-menu-item key="businessInvoice" :class="active==='businessInvoice' ||active==='ticketSure'?'ant-menu-item-selected':''">
              开票管理
            </a-menu-item> -->
            <!-- <a-menu-item key="financeManage" :class="active==='financeManage'||active==='/financeManage'||active==='ticketDetail'?'ant-menu-item-selected':''">
              发票记录
            </a-menu-item> -->
            
            
          </a-sub-menu>
          <!-- <a-sub-menu key="sub1">
            <span slot="title"><span> <img src="../assets/menu_01.png" class="img" alt="">财务中心</span></span>
            <a-menu-item :class="active==='settlementManage'?'ant-menu-item-selected':''" key="settlementManage">
              付款管理
            </a-menu-item>
            <a-menu-item :class="active==='rechargeManage'?'ant-menu-item-selected':''" key="rechargeManage">
              充值管理
            </a-menu-item>
          </a-sub-menu> -->
          <a-sub-menu key="sub2">
            <span slot="title"><span> <img src="../assets/icon/invoice_selected.png" class="img" alt="" v-if="active==='businessInvoice'||active==='/businessInvoice'||active==='financeManage'||active==='ticketSure'"><img src="../assets/icon/invoice.png" class="img" alt="" v-else>发票管理</span></span>
            <a-menu-item key="businessInvoice" :class="active==='businessInvoice' ||active==='ticketSure'?'ant-menu-item-selected':''">
              开票管理
            </a-menu-item>
            <a-menu-item key="financeManage" :class="active==='financeManage'||active==='/financeManage'||active==='ticketDetail'?'ant-menu-item-selected':''">
              发票记录
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <div class="right">
        <div style="background:#FFFFFF">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ACCESS_TOKEN,USER_INFO } from "@/store/mutation-types";
import {mapActions} from 'vuex';
import Vue from 'vue';
import {
  productionList,
  getPubFunAndItem,
  getUserLoginInfo
} from '@/api/api';
export default {
  name: 'Layout',
  data () {
    return {
      active: '',
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      openKeys: ['sub1','sub2'],
      userInfo: null,
      navIndex: 0,
      meta: '',
      searchList: [],
      visibleCommonMenu: false,
      placement: 'left',
      productionMenu: [],
      commonMenu: [],
      itemMenu: [],
      token: '',
    }
  },
  created () {
    // this.userInfo = Vue.ls.get('Login_Userinfo')
    
    
    this.token = Vue.ls.get(ACCESS_TOKEN)
    this.getUserInfo()
    this.getProductionMenu()
    this.getCommonMenu()
    this.meta = this.$route.meta
    let smenu = window.location.href.split('?')[0].split('#/')[1]
    console.log('smenu',smenu);
    let menu = window.localStorage.getItem('submenu') || smenu
    let token = window.location.href.split('?')[1];
    if (token) {
      let value = token.split('=')[1]
      Vue.ls.set(ACCESS_TOKEN, value, 7 * 24 * 60 * 60 * 1000)
    }
      console.log('menu',menu);
    if (menu) {
      
      // let localMenu = window.location.href.split('?')[0].split('#')[0] + '#/' + menu
      // window.location.href = localMenu
      this.active = menu
      if (menu==='raskAdd'||menu==='raskDetail'||menu==='raskDetailMany') {
        this.active = 'raskManage'
      }
      
      if (menu=='settlementDetail') {
        this.active = 'settlement'
        this.$router.push('/settlementDetail')
        
      }
      // if (menu==='ticketDetail'||menu==='ticketSure') {
      //   this.active = 'kkfp'
      // }
    }
  },
  beforeDestroy () {
    
    window.localStorage.removeItem("submenu");
  }
  ,
  methods: {
    ...mapActions([ "Login", "WxLogin", "Logout" ]),
    logout () {
      this.Logout().then(() => {
        this.$router.push('/')
      })
    },
    // 获取用户信息
    getUserInfo () {
      getUserLoginInfo().then(res => {
        if (res.success) {
          this.userInfo = res.result
          this.userInfo.realname = this.userInfo.nikeName
          Vue.ls.set('Login_Userinfo',this.userInfo)
        }
      })
    },
    // 回到首页
    toHome () {
      window.localStorage.setItem('submenu','home')
      this.pushRouter('home')
    },
    // 获取产品菜单
    getProductionMenu () {
      productionList({pageNo:1,pageSize:100}).then(res => {
        if (res.success) {
          this.productionMenu = res.result.data
          this.productionMenu.forEach(item=>{
            item.productUrl = item.productUrl + '?token=' + this.token
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取公用菜单
    getCommonMenu () {
      getPubFunAndItem().then(res => {
        if (res.success) {
          this.commonMenu = res.result
          this.commonMenu.forEach(item=>{
            item.funUrl = item.funUrl + '?token=' + this.token
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    ggMenu(item) {
      console.log(66);
      this.visibleCommonMenu = false
      
      window.localStorage.setItem('submenu','raskManage')
    },
    // 关闭产品菜单
    closeCommonMenu() {
      this.visibleCommonMenu = false;
    },
    // 搜索菜单
    handleSearch(value) {
      console.log(value)
    },
    handleChange(value) {
      console.log(value);
    },
    // 选择header-nav
    handleClickNavMenu (e) {
      console.log(e)
    },
    // 获取用户登录信息
    getLoginInfo () {
      getLoginInfo().then(res => {
        if (res.success) {
          this.userInfo = res.result;
        }
      })
    },
    // submenu改变
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // 跳转
    pushRouter (value) {
      this.$router.push("/"+value)
    },
    // 选择submenu时跳转至相对应页面
    selectIt(value){
      window.localStorage.setItem('submenu',value.key)
      this.active = value.key
      this.pushRouter(value.key)
    },
  }
}
</script>
<style lang="less" scoped>
.welcome_bg{
  background: url('../assets/index_bg.png') no-repeat 0 0 ;
  background-size: cover;
}
.container{
  width: 100%;
  height: 100%;
}
.header{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  position: relative;
  .header-content{
    position: absolute;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 10;
    .header-left{
      display: flex;
      align-items: center;
      .total-menu{
        width: 50px;
        height: 50px;
        margin-right: 25px;
        img{
          width: 50px;
          height: 50px;
          cursor: pointer;
        }
      }
      img{
        height: 32px;
        margin-right: 7px;
        cursor: pointer;
      }
      .name{
        font-size: 21px;
        font-family: 'FZZhengHeiS-M-GB';
        color: #202020;
      }
      .eg-name{
        font-size: 8px;
        font-family: 'FZZhengHeiS-M-GB';
        font-weight: 400;
        color: #202020;
        letter-spacing: 2px;
      }
    }
    .header-right{
      padding: 0 4px 0 0;
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      li{
        margin-right: 26px;
        font-size: 12px;
        .single{
          display: inline-block;
          text-align: center;
          height: 64px;
          line-height: 64px;
          cursor: pointer;
          color: #333;
          &:hover{
            color: #1890ff;
          }
        }
        .main-name{
          height: 64px;
          line-height: 64px;
          cursor: pointer;
          color: #333;
          &:hover{
            color: #1890ff;
          }
        }
      }
      .user-info{
        display: flex;
        align-items: center;
        .header-right-title{
          width: 30px;
          height: 30px;
          background: #6379F5;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          font-size: 12px;
          color: #FFFFFF;
          margin-right: 7px;
        }
        .header-right-name{
          // width: 143px;
          color: #202020;
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .relative{
        position: relative;
        .search-icon{
          position: absolute;
          right: 5px;
          top: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
.img{
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.content{
  box-sizing: border-box;
  display: flex;
  // justify-content: center;
  position: relative;
  width: 100%;
  
  
  .left{
    box-sizing: border-box;
    width: 220px;
    max-width: 220px;
    min-width: 220px;
    // height: 969px;
    background: #FFFFFF;
    border-radius: 3px;
    padding-top: 10px;
    .left-title{
      padding: 10px 0 10px 50px;
      font-size: 14px;
      color: #737373;
      font-weight: bold;
      border-bottom: 1px solid #E3E4E6;
    }
    ul{
      padding: 0;
      list-style: none;
      li{
        border-left: 3px solid transparent;
        width: 220px;
        box-sizing: border-box;
        padding: 15px 0 15px 47px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
      cursor: pointer;
      .active{
        background: #E6F7FF;
        border-left: 3px solid #2878FF;
        color: rgba(40, 120, 255, 0.85);
      }
    }
  }
  .right{
    background: #F3F3F3;
    box-sizing: border-box;
    // overflow-x: scroll;
    // width: 100%;
    flex: auto;
    overflow: hidden;
    padding: 14px;
    min-height: 969px;
    // max-width: 1600px;
    // width: 1600px;
    border-radius: 3px;
    &::after{
      display: block;
      content: '';
      clear: both;
      margin-bottom: 30px;
    }
  }
  .commom-menu{
    padding: 0;
    margin: 6px 0 0 0;
    list-style: none;
    li{
      a{
        height: 54px;
        padding: 0 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
      }
      &:hover{
        background: #F7F9FA;
      }
      img{
        width: 16px;
        height: 16px;
        margin-right: 18px;
      }
      span{
        color: #333333;
        font-size: 12px;
      }
    }
  }
}
.content .left ul .ant-menu-item{
  padding: 0 0 0 24px;
}
.content .left ul .ant-menu-submenu{
  padding: 0 1px 0 0px;
}
.content .left ul .ant-menu-submenu .ant-menu-item{
  width: 220px;
  margin-left: -3px;
}
.content .left ul .ant-menu-item-selected{
  border-left: 3px solid #2878FF;
  color: rgba(0, 0, 0, 0.85);
  border-right: 0;
  background: #EDEFF3;
  &:after{
    display: none;
  }
}
</style>
<style>

  .content .ant-drawer-body{
    padding: 0 !important;
  }
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
  display: none !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow{
  display: none;
}
.content .left ul{
  border-right: 0;
}
.ant-drawer-title{
  font-size: 14px !important;
}
.ant-menu-inline .ant-menu-submenu-title {
  font-size: 12px !important;
}
</style>