<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-06 16:17:08
 * @LastEditTime: 2021-04-23 09:08:42
-->
<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-locale-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import Vue from 'vue';
import { ACCESS_TOKEN } from "@/store/mutation-types";
export default {
  created () {
    let href = window.location.href.split('?')[1];
    if (href) {
      let token = href.split('=')[1];
      Vue.ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000);
    }
  },
  data () {
    return {
      locale: zhCN,
    }
  },
  beforeDestroy () {
    window.localStorage.removeItem("menu");
  }
}
</script>
<style>
#app{
  width: 100%;
  height: 100%;
}
*{
  box-sizing: border-box;
  font-family: 'PingFang SC';
}
.table-search-submitButtons{
  display: flex;
  align-items: center;
  height: 40px;
}
</style>
