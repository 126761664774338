import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录',
      slideMenu: false
    },
    component: () => import('../views/login/login')
  },
  {
    path: '/home',
    name: 'Login',
    meta: {
      title: '首页',
      slideMenu: false
    },
    component: Layout,
    children: [
      {
        path: '/home',
        meta: {
          title: '首页',
          slideMenu: false
        },
        component: () => import('../views/home/home')
      },
    ]
  },
  // 任务管理
  {
    path: '/raskManage',
    meta: {
      title: '任务管理',
      slideMenu: true
    },
    component: Layout,
    children: [
      {
        path: '/raskManage',
        meta: {
          title: '企业管理',
          slideMenu: true
        },
        // component: () => import('../views/raskManage/rask')
        component: () => import('../views/enterprise/enterpriseManage')
      },
      {
        path: '/raskAdd',
        meta: {
          title: '新增任务',
          slideMenu: true
        },
        component: () => import('../views/raskManage/raskAdd')
      },
      {
        path: '/raskDetail/:id',
        name: 'raskDetail',
        meta: {
          title: '详情配置',
          slideMenu: true
        },
        // component: () => import('../views/raskManage/raskDetail')
        component: () => import('../views/raskManage/raskAdd1')
        // component: () => import('../views/enterprise/detailConfig')
      },
      {
        path: '/raskDetailMany',
        name: 'raskDetailMany',
        meta: {
          title: '新增详情',
          slideMenu: true
        },
        component: () => import('../views/raskManage/raskDetailMany')
      }
    ]
  },
  // 开票管理
  {
    path: '/kkfp',
    meta: {
      title: '开票管理'
    },
    component: Layout,
    children: [
      {
        path: '/kkfp',
        meta: {
          title: '开票管理',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticket')
      },
      {
        path: '/ticketDetail',
        meta: {
          title: '开票详情',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticketDetail')
      },
      {
        path: '/fpxx',
        meta: {
          title: '发票信息',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticketInfo')
      },
      {
        path: '/ticketSure',
        meta: {
          title: '确认发票',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticketSure')
      }
    ]
  },
  // 结算管理
  {
    path: '/settlementManage',
    meta: {
      title: '付款管理'
    },
    component: Layout,
    children: [
      {
        path: '/settlementManage',
        meta: {
          title: '付款管理',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/settlement')
      },
      {
        path: '/settlementDetail',
        meta: {
          title: '付款详情',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/settlementDetail')
      },
      {
        path: '/rechargeManage',
        meta: {
          title: '充值管理',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/rechargeManage')
      }
    ]
  },
  //交易单
  {
    path: '/tradingCenter',
    meta: {
      title: '交易管理',
      slideMenu: true
    },
    component: Layout,
    children: [
      {
        path: '/tradingCenter',
        meta: {
          title: '交易管理',
          slideMenu: true
        },
        component: () => import('../views/tradingCenter/TransactionTicket')
      }
    ]
  },
  //任务管理
  {
    path: '/taskCenter',
    meta: {
      title: '任务管理',
      slideMenu: true
    },
    component: Layout,
    children: [
      {
        path: '/taskCenter',
        meta: {
          title: '任务管理',
          slideMenu: true
        },
        component: () => import('../views/taskManagement/index')
        // component: () => import('../views/raskManage/raskDetail.vue')
      },
      {
        path: '/addTask',
        meta: {
          title: '新建任务',
          slideMenu: true
        },
        component: () => import('../views/raskManage/raskAdd.vue')
      },
      {
        path: '/taskDetail/:id',
        meta: {
          title: '任务详情',
          slideMenu: true
        },
        component: () => import('../views/taskManagement/taskDetail.vue')
      },
      {
        path: '/payDetail/:id',
        meta: {
          title: '付款详情',
          slideMenu: true
        },
        component: () => import('../views/taskManagement/payDetail.vue')
      }
    ]
  },
  //财务管理
  {
    path: '/settlement',
    meta: {
      title: '财务管理',
      slideMenu: true
    },
    component: Layout,
    children: [
      
      
      {
        path: '/rechargeOrder',
        meta: {
          title: '充值订单',
          slideMenu: true
        },
        component: () => import('../views/ticket/rechargeOrder')
      },
      {
        path: '/settlement',
        meta: {
          title: '付款管理',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/settlement.vue')
      },
      
    ]
  },
  //发票管理
  {
    path: '/financeManage',
    meta: {
      title: '财务管理',
      slideMenu: true
    },
    component: Layout,
    children: [
      {
        path: '/financeManage',
        meta: {
          title: '财务管理',
          slideMenu: true
        },
        component: () => import('../views/ticket/index')
      },
      {
        path: '/businessInvoice',
        meta: {
          title: '企业发票',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticket')
      },
      {
        path: '/rechargeOrder',
        meta: {
          title: '充值订单',
          slideMenu: true
        },
        component: () => import('../views/ticket/rechargeOrder')
      },
      {
        path: '/settlement',
        meta: {
          title: '付款管理',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/settlement.vue')
      },
      {
        path: '/ticketDetail',
        meta: {
          title: '发票详情',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticketDetail.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
