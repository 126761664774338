import { getAction, deleteAction, putAction, postAction } from '@/api/manage'

const queryPermissionsByUser = (params) => getAction("/sys/permission/getUserPermissionByToken", params);
// 获取产品菜单
const productionList = (params) => getAction('/api/tax/sys/product/list', params);
// 获取公用菜单
const getPubFunAndItem = (params) => getAction('/api/tax/sys/productPubFun/getPubFunAndItem', params);
// 检查是否绑定微信
const checkBindWxMq = (params) => getAction('/api/tax/client/user/checkBindWxMq', params);
// 获取任务列表
const getRaskList = (params) => getAction('/slash-youth/client/b/task/list', params)
// 新建任务
const raskListAdd = (params) => postAction('/slash-youth/client/b/task/pushTask', params)
// 获取任务类型
const getRaskType = (params) => getAction('/api/tax/client/enterprise/trade/task/getTaskType2', params)
// 取消任务
const raskCancel = (params) => putAction(`/slash-youth/client/b/task/cancelTask/${params}`)
// 结束任务
const raskEnd = (params) => putAction(`/slash-youth/client/b/task/endApply/${params}`)
// 任务详情
const raskDetail = (params) => getAction(`/slash-youth/client/b/task/queryById`, params)
// 获取任务工作者
const getWorkList = (params) => getAction('/slash-youth/client/b/taskWorker/list', params)
// 签约
const raskAssign = (params) => postAction('/slash-youth/client/b/taskWorker/confirmApply', params)
// 拒绝签约
const refuseAssign = (params) => postAction('/slash-youth/client/b/taskWorker/refuseConfirmed', params)
// 取消签约
const cancelAssign = (params) => postAction('/slash-youth/client/b/taskWorker/cancelSign', params)
// 获取任务类别名称
const getRaskTypeName = (params) => getAction(`/api/tax/client/enterprise/trade/task/getTaskTypeById/${params}`)
// 获取预付款尾款列表
const taskWorkerPayItem = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/list', params)
// 获取统计预付款尾款
const settlementStatistical = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/settlementStatistical', params)
// 获取结算单详情
const getPayDetails = (params) => getAction('/slash-youth/client/b/taskWorkerPay/getPayDetails', params)
// 提交任务订单
const taskWorkerPaySubmit = (params) => postAction('/slash-youth/client/b/taskWorkerPay/submit', params)
// 获取任务审核列表
const getByWorkerIds = (params) => getAction(`/slash-youth/client/b/taskWorkerResult/queryPageList`, params)
// 审核任务
const taskWorkerResult = (params) => postAction('/slash-youth/client/b/taskWorkerResult/audit', params)

// 结算单
// 任务工作支付单
const getOrderList = (params) => getAction('/slash-youth/channel/task/payList', params)
// 复核
const taskWorkerPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/review', params)
// 获取账户余额
const getBalance = (params) => getAction(`/api/tax/client/user/wallet/getWalletByType/${params}`)
// 预支付
const prePay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/prePay', params)
// 支付
const taskPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/pay', params)
// 获取发票列表
const ticketList = (params) => getAction('/slash-youth/client/b/task/confirmInvoiceList', params)
// 查询开票信息
const ticketInfos = (params) => getAction('/api/tax/client/invoice/getInvoiceTradesInfo', params)
// 查询收件人信息
const getInvoiceMailed = () => getAction('/api/tax/client/enterprise/receipt/getInvoiceMailed')
const getInvoiceMailedByEnterpriseId = (params) => getAction('/api/tax/client/enterprise/receipt/getInvoiceMailedByEnterpriseId/' + params)
// 提交开票
const submitTicket = (params) => postAction('/slash-youth/client/b/task/confirmInvoice', params)
// 查看历史开票
const getApplyInvoiceTradeList = (params) => getAction('/api/tax/admin/enterprise/invoice/trade/getApplyInvoiceTradeList', params)
// 获取发票详情
const getApplyInvoiceTradeDetails = (params) => getAction(`/api/tax/admin/enterprise/invoice/trade/getApplyInvoiceTradeDetails/${params}`)
// 获取发票内容
const getInvoiceByIndustryId = (params) => getAction(`/api/tax/client/invoice/getInvoiceByIndustryId/${params}`)
const getInvoiceByIndustryId2 = (params) => getAction(`/api/tax/client/invoice/getInvoiceByIndustryId2`,params)
// 获取邮箱
const getUserBindEmail = () => getAction('/api/tax/client/user/getUserBindEmail')
// 查询邀请人员
const getUserByPhone = (params) => getAction(`/api/tax/admin/user/getUserByPhone/${params}`)
// 邀请
const invitation = (params) => postAction('/slash-youth/client/b/taskWorker/invitation', params)
// 重置密碼
const resetPwd = (params) => postAction('/api/tax/client/user/payPassword/forget', params)
// 手机号发送短信
const sendInvitationSms = (params) => getAction(`/slash-youth/client/b/taskWorker/sendInvitationSms/${params}`)
// 推荐优质人才列表
const getRandomRecommendUser = () => getAction('/api/tax/admin/user/getRandomRecommendUser')
// 检验是否已报名
const checkRepetition = (params) => getAction('/slash-youth/client/b/taskWorker/checkRepetition', params)
const invitationRecommendUser = (params) => postAction('/slash-youth/client/b/taskWorker/invitationRecommendUser', params)
// 获取用户假数据
const getUserData = (params) => getAction('/api/client/sys/getUserData', params)
// getEnterpriseTradeDetaileds
const getEnterpriseTradeDetaileds = (params) => getAction('/api/tax/client/enterprise/trade/queryEnterpriseTradeDetaileds', params)
// 查询当前用户角色
const getRoleType = (params) => getAction('/api/tax/client/enterprise/trade/getRoleType', params)
// 批量邀请
const batchInvitationRecommendUser = (params) => postAction('/slash-youth/client/b/taskWorker/batchInvitationRecommendUser', params);
// 获取费率
const getServiceRule = (params) => getAction('/api/tax/client/enterprise/sign/getServiceRule', params);
// 获取费率
const getFeePoint = (params) => getAction('/api/tax/client/enterprise/sign/getFeePoint', params);
// 查询批量上传工作者列表
const queryImportWorkerList = (params) => getAction('/slash-youth/client/b/task/queryImportWorkerList', params)
// 提交批量上传工作者列表
const submitApply = (params) => postAction('/slash-youth/client/b/task/submitApply', params);
// 查询批量上传工作者列表汇总
const importWorkerStatistics = (params) => getAction(`/slash-youth/client/b/task/importWorkerStatistics/${params}`)
// 获取银行卡号
const getBanknum = (params) => getAction('/api/tax/client/user/wallet/recharge/getRechargeInfo/bank', params);
// 获取充值记录
const getRecharges = (params) => getAction('/api/tax/client/user/wallet/recharge/getRecharges2', params);
// 获取账户余额
const getRechargesAccount = (params) => getAction('/api/tax/client/user/wallet/getWalletByType/bank', params);
// 添加周期
const addPeriodPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/addPeriodPay', params);
// 添加周期
const taskWorkerPayItemList = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/list', params);
// 取消付款
const cancelPay = (params) => postAction(`/slash-youth/client/b/taskWorkerPayItem/cancelPay?id=` + params);
// 提交结算
const submitPeriodPay = (params) => postAction(`/slash-youth/client/b/taskWorkerPay/submitPeriodPay`, params);
// 提交结算
const taskWorkerPayList = (params) => getAction(`/slash-youth/client/b/taskWorkerPay/list`, params);
// 确认申请发票
const confirmInvoice = (params) => postAction(`/slash-youth/client/b/taskWorkerPay/confirmInvoice`, params);
// 已开发票
const invoiceList = (params) => getAction(`/slash-youth/client/b/taskWorkerPay/invoiceList`, params);
// 关闭任务
const fulFilTask = (params) => postAction(`/slash-youth/client/b/task/fulFilTask/${params}`);
// 修改任务
const tastEdit = (params) => postAction('/slash-youth/client/b/task/edit', params);
// 获取用户信息
const getUserLoginInfo = (params) => getAction('/api/tax/client/user/getUserInfo', params);
// 首页获取企业信息
const getEnterpriseReserveInfo = (params) => getAction('/api/tax/client/enterprise/getEnterpriseReserveInfo', params);
// 获取支付金额
const getPayStatistics = () => getAction('/api/tax/client/enterprise/trade/getPayStatistics//success');
// 获取账户总金额
const getWalletByType = () => getAction('/api/tax/client/user/wallet/getWalletByType/bank');
// 获取待开票金额
const getInvoiceTradesAmount = () => getAction('/api/tax/client/invoice/getInvoiceTradesAmount');
// 重置金额
const recharge = () => getAction('/api/tax/client/enterprise/main/rechargeStatistics');
// 订单笔数
const tradeCount = () => getAction('/api/tax/client/enterprise/main/tradeCount');
// 系统消息
const notice = (params) => getAction('/api/tax/client/sys/notice/getNotices', params);
// 曲线图数据接口
const getLineChart = (params) => getAction('/api/tax/client/enterprise/main/getLineChart', params);
// 上传任务结果
const enterpriseAdd = (params) => postAction('/slash-youth/client/b/taskWorkerResult/enterpriseAdd', params);
const enterpriseUnifyAdd2 = (params) => postAction('/slash-youth/client/b/taskWorkerResult/enterpriseUnifyAdd2', params);
const enterpriseUnifyAdd = (params) => postAction('/slash-youth/client/b/taskWorkerResult/enterpriseUnifyAdd', params);
// 查询白名单
const isWhiteList = (params) => getAction('/slash-youth/client/b/task/isWhiteList', params);
// 查询邮寄地址
const getDefaultInvoiceMailed = () => getAction('/api/tax/client/enterprise/receipt/getDefaultInvoiceMailed')




//企业管理
// 查询所有省份地区
const getSysRegion = (params) => getAction(`/api/tax/admin/enterprise/getSysRegion`);
// 查询行业
const getSysIndustry = (params) => getAction('/api/tax/admin/enterprise/getSysIndustry', params);
// 获取企业列表
const getEnterpriseList = (params) => getAction(`/api/tax/admin/enterprise/list/`, params);
// 获取企业列表(员工管理)
const getStaffEnterpriseList = (params) => getAction(`/api/tax/sys/enterprise/staff/getEnterpriseList`, params);
// 添加企业基本信息
const addEnterpriseBaseInfo = (params) => postAction("/api/tax/admin/enterprise/addEnterpriseBaseInfo2", params);
// 修改企业信息
const updateEnterpriseBaseInfo = (params) => putAction(`/api/tax/admin/enterprise/updateEnterpriseBaseInfo`, params);
// 获取企业基本信息
const getEnterpriseBaseInfo = (params) => getAction(`/api/tax/admin/enterprise/getEnterpriseBaseInfo/${params}`,);
// 审核企业
const examineEnterprise = (params) => putAction(`/api/tax/admin/enterprise/examineEnterprise2`, params);
// 禁用
const stopCooperate = (params) => putAction(`/api/tax/admin/enterprise/stopCooperate/${params}`);
// 启用
const startCooperate = (params) => putAction(`/api/tax/admin/enterprise/startCooperate/${params}`);
// 查询企业员工列表
const getStaffList = (params) => getAction(`/api/tax/sys/enterprise/staff/list`, params);
// 新增企业员工
const addEnterpriseStaff = (params) => postAction("/api/tax/sys/enterprise/staff/addEnterpriseStaff", params);
// 冻结员工
const freezeEnterpriseStaff = (params) => putAction(`/api/tax/sys/enterprise/staff/freezeEnterpriseStaff?id=${params}`);
// 获取登录账户列表
const getAccountList = (params) => getAction(`/api/tax/admin/enterprise/account/list`, params);
// 冻结员工登录账户
const freezeEnterpriseAccount = (params) => putAction(`/api/tax/admin/enterprise/account/freezeEnterpriseAccount?id=${params}`);
// 重置登录账户登录密码
const resetAccountPassword = (params) => putAction(`/api/tax/admin/enterprise/account/resetPassword?id=${params}`);
// 获取企业配置信息
const getEnterpriseConfig = (params) => getAction(`/api/tax/admin/enterprise/getEnterpriseConfig/${params}`);
// 修改企业配置信息
const updateEnterpriseConfig = (id, params) => putAction(`/api/tax/admin/enterprise/updateEnterpriseConfig/${id}`, params);
// 获取企业经营范围列表
const getEnterpriseScopeList = (params) => getAction(`/api/tax/admin/enterprise/business/scope/list`, params);
// 查询系统经营范围列表1
const getSysBusinessScopeList = (params) => getAction(`/api/tax/admin/enterprise/business/scope/getSysBusinessScopeList`, params);
// 查询系统经营范围列表2
const getBusinessScopeBox = (params) => getAction(`/api/tax/admin/enterprise/business/scope/getBusinessScopeBox`, params);
// 获取经营范围系统默认费率
const getSysBusinessScopeRate = () => getAction(`/api/tax/admin/enterprise/business/scope/getSysBusinessScopeRate`);
// 添加企业经营范围
const addEnterpriseBusinessScope = (params) => postAction("/api/tax/admin/enterprise/business/scope/addEnterpriseBusinessScope", params);
// 冻结企业经营范围
const freezeEnterpriseBusinessScope = (params) => putAction(`/api/tax/admin/enterprise/business/scope/freezeOrThawEnterpriseBusinessScope?id=${params.id}&status=${params.status}`);
// 获取企业合同列表
const getEnterpriseContractList = (params) => getAction(`/api/tax/admin/enterprise/contract/list`, params);
// 添加企业合同
const addEnterpriseContract = (params) => postAction("/api/tax/admin/enterprise/contract/addEnterpriseContract", params);
// 新增企业开票信息
const addCompanyBankInfo = (params) => postAction('/api/tax/admin/enterprise/invoiceInfo/add', params);
// 修改企业开票信息
const updateCompanyBankInfo = (params) => putAction('/api/tax/admin/enterprise/invoiceInfo/edit', params);
// 修改企业认证信息
const updateCompanyIndentifyInfo = (params => putAction('/api/tax/admin/enterprise/updateEnterpriseAuthentication', params));
// 获取企业认证信息
const getCompanyIndentifyInfo = (params => getAction(`/api/tax/admin/enterprise/getEnterpriseAuthentication/${params}`));
// 获取企业开票信息
const getCompanyBankInfo = (params => getAction(`/api/tax/admin/enterprise/invoiceInfo/getByEnterpriseId/${params}`));
// 新增企业返利配置
const addRebateRules = (params) => postAction('/api/tax/admin/taxEnterpriseServiceRule/addEnterpriseServiceRule', params);
// 查询企业返利配置接口
const getRebateRules = (params) => getAction('/api/tax/admin/taxEnterpriseServiceRule/queryByEnterpriseId', params);
// 修改企业返利配置接口
const updataRebateRules = (params) => putAction('/api/tax/admin/taxEnterpriseServiceRule/edit', params);
// 获取企业开票信息
const getEnterpriseBankInfo = (params) => getAction(`/api/tax/admin/enterprise/getEnterpriseBank/${params}`);
// 一键新增董监高
const initEnterpriseStaff = (params) => putAction('/api/tax/sys/enterprise/staff/initEnterpriseStaff', params);
// 解冻董监高
const unfreezeEnterpriseStaff = (params) => putAction(`/api/tax/sys/enterprise/staff/unfreezeEnterpriseStaff?id=${params}`);
// 获取预签约
const getPreSignLists = (params) => getAction('/api/tax/admin/enterprise/user/sign/list', params);
// 新增预签约用户
const addUserSign = (params) => postAction('/api/tax/admin/enterprise/user/sign/addUserSign', params);
// 认证预签约用户
const authAddUserSign = (params) => postAction('/api/tax/admin/enterprise/user/sign/addAuthenticationInfo', params);
// 快速新增预签约用户
const addSignUser = (params) => postAction('/api/tax/admin/enterprise/user/sign/addSignUser', params);
// 保存企业发票邮寄地址
const saveEnterpriseInvoiceMailed = (params) => postAction('/api/tax/admin/enterprise/invoice/mailed/saveEnterpriseInvoiceMailed', params);
const getEnterpriseInvoiceMailed = (params) => getAction(`/api/tax/admin/enterprise/invoice/mailed/getEnterpriseInvoiceMailed/${params}`);


//企业管理中心  企业管理  提成配置-查询
const commissionQuery = (params) => getAction(`/api/tax/admin/enterprise/commission/query`, params);

//企业管理中心  企业管理  提成配置-修改提成扣点
const commissionUpdate = (params) => postAction(`/api/tax/admin/enterprise/commission/update`, params);

// 查询邮箱地址
const addEmailList = (params) => postAction('/api/tax/admin/enterprise/invoice/mailed/add',params);

// 重复校验
const duplicateCheck = (params)=>getAction("/api/sys/duplicate/check",params);
// 查询邮箱地址
const editEmailList = (params) => postAction('/api/tax/admin/enterprise/invoice/mailed/update',params);
// 费用配置添加
const feeAdd = (params) => postAction('/api/tax/admin/enterprise/fee/add',params);
// 费用配置审核
const feeAudit = (params) => postAction('/api/tax/admin/enterprise/fee/audit',params);
// 费用配置查询
const feeQuery = (params) => getAction(`/api/tax/admin/enterprise/fee/queryItem`,params);
// 获取代理商
const getAgentBox = (params) => getAction('/api/sys/user/sales/getAgentBox',params);
// 查询代理商信息
const getDlsInfo = (params) => getAction(`/api/tax/admin/enterprise/getSaleInfo/${params}`);
// 查询邮箱地址
const getEmailList = (params) => getAction('/api/tax/admin/enterprise/invoice/mailed/getEnterpriseInvoiceMailed',params);
// 查询业务员
const getEmployee = (params) => getAction('/api/tax/admin/enterprise/getSalesman',params);
// 修改费用配置
const taxEnterpriseServiceRuleItemDel = (params) => deleteAction('/api/tax/admin/taxEnterpriseServiceRuleItem/delete',params);
// 修改费用配置
const taxEnterpriseServiceRuleItemSave = (params) => postAction('/api/tax/admin/taxEnterpriseServiceRuleItem/add',params);
const taxEnterpriseServiceRuleItemEdit = (params) => postAction('/api/tax/admin/taxEnterpriseServiceRuleItem/edit',params);
// 启用停用合同
const updateEnterpriseContractStatus = (params) => postAction('/api/tax/admin/enterprise/contract/updateEnterpriseContractStatus',params);
// 修改提现方式
const updateWithdrawalModel = (params) => postAction('/api/tax/admin/enterprise/updateWithdrawalModel',params);
// 上传文件
const uploadFile = (params) => postAction('/api/tax/common/file/upload', params);


//查询代理渠道企业列表
const getChanneList = (params) => getAction('/api/tax/admin/enterprise/channe/list',params);

//渠道管理任务表-分页列表查询
const getTaskChanneList = (params) => getAction('/slash-youth/admin/task/channe/list',params);



// 处理清算行信息
const disposeWithdrawal = (params) => postAction(`/api/tax/admin/user/wallet/withdrawal/disposeWithdrawal`, params);
// 获取企业下拉列表
// const getEnterpriseDownBox = (params) => getAction('/api/tax/admin/enterprise/getEnterpriseDownBox', params);//管理平台
const getEnterpriseDownBox = (params) => getAction('/api/tax/admin/enterprise/channe/getEnterpriseBox', params);//渠道管理
// 获取企业交易明细单列表
const getEnterpriseTradeDetailedList = (params) => getAction(`/api/tax/admin/enterprise/trade/getEnterpriseTradeDetailedList`, params);
// 获取企业交易主单列表
const getEnterpriseTradeMainList = (params) => getAction(`/api/tax/admin/enterprise/trade/getEnterpriseTradeMainList`, params);
// 获取模板内容
const getTradeTaskTemplate = (params) => getAction(`/api/tax/client/enterprise/trade/task/getTradeTaskTemplate/${params}`);
// 获取电子回单
const getTradeWithdrawal = (params) => getAction(`/api/tax/admin/user/wallet/withdrawal/getTradeWithdrawal/${params}`);
// 获取电子回单付款日志
const getTradeWithdrawalDetail = (params) => getAction(`/api/tax/admin/user/wallet/withdrawal/getTradeWithdrawalPayLog/${params}`);
// 获取电子回单
const getWalletWithdrawalBankReceipt = (params) => getAction(`/api/tax/admin/enterprise/trade/getWalletWithdrawalBankReceipt/${params}`);
// 获取任务详情
const getqueryTradeTask = (params) => getAction(`/api/tax/client/enterprise/trade/task/queryTradeTask/${params}`);
// 查询付款是否成功
const queryResult = (params) => getAction(`/api/tax/admin/user/wallet/withdrawal/queryResult/${params}`);
// 获取确认单
const queryTradeConfirm = (params) => getAction(`/api/tax/client/enterprise/tradeConfirm/queryTradeConfirm/${params}`);
// 任务单类别
const taskType = (params) => getAction(`/api/tax/admin/enterprise/trade/getIndustryType`);
// 退款
const tradeRefund = (params) => postAction('/api/tax/admin/enterprise/trade/tradeRefund', params);
// 修改清算行信息
const updateQsBank = (params) => postAction(`/api/tax/admin/user/wallet/withdrawal/updateQsBank`, params);
// 修改交易单明细备注
const updateRemark = (params) => postAction('/api/tax/admin/enterprise/trade/updateRemark',params);
// 修改交易单
const updateTradeConfirm = (params) => postAction('/api/tax/admin/enterprise/trade/updateTradeConfirm', params);
// 修改任务单
const updateTradeTask = (params) => postAction('/api/tax/admin/enterprise/trade/updateTradeTask', params);

// 任务单类别
const getAgentEnterpriseTradeMainList = (params) => getAction(`/api/tax/admin/enterprise/trade/getAgentEnterpriseTradeMainList`,params);


const exportEnterpriseInvoiceTradesAmount = function downFile(parameter){
  return axios({
    url: '/api/tax/admin/enterprise/invoice/exportEnterpriseInvoiceTradesAmount',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}

const exportReportInvoiceList = function downFile(parameter){
  return axios({
    url: '/api/tax/admin/enterprise/invoice/trade/exportReportInvoiceList',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}
// 获取发票中心列表
const getEnterpriseInvoiceTradesAmountList = (params) => getAction('/api/tax/admin/enterprise/invoice/getEnterpriseInvoiceTradesAmountList',params);
// 获取可开票明细
const getInvoiceTradesAmountList = (params) => getAction('/api/tax/admin/enterprise/invoice/getInvoiceTradesAmountList',params);

//查询申请发票
const getChanneApplyInvoiceTradeList = (params) => getAction('/api/tax/admin/enterprise/invoice/trade/getChanneApplyInvoiceTradeList',params);
// 获取任务结果列表
const getTaskResult = (params)=>getAction(`/slash-youth/admin/taskWorkerResult/queryPageList`,params);
// 获取任务类别
const getTaskType = (params) => getAction(`/api/tax/client/invoice/getById/${params}`);
// 获取工作者名单
const taskConfirmOrderList = (params)=>getAction('/slash-youth/admin/taskWorker/list',params);
// 查看任务详情
const taskQueryById = (params) => getAction('/slash-youth/admin/task/queryById',params);

//查询用户账户充值列表
const getRechargeChanneList = (params) => getAction('/api/tax/admin/user/wallet/recharge/channe/list',params);

//获取用户登录账号信息
const getUserLoginInfoNowAccount = (params) => getAction('/api/tax/client/user/getUserLoginInfo',params);
//查询企业付款账户信息
const getEnterprisePayConfig = (params) => getAction('/api/tax/client/enterprise/getEnterprisePayConfig/'+ params);
// 发票明细
const getApplyInvoiceDetails = (params) => getAction('/api/tax/client/invoice/getApplyInvoiceDetails', params);
// 查询上传发票
const getInvoicePicture = (params) => getAction(`/api/tax/admin/enterprise/invoice/trade/getInvoicePicture/${params}`);
// 处理发票申请(状态需要为已申请)
const handleInvoiceApply = (params) => putAction(`/api/tax/admin/enterprise/invoice/handleInvoiceApply?id=${params}`);
// 发票撤销申请
const repealApply = (params) => postAction('/api/tax/admin/enterprise/invoice/trade/repealApply',params);
// 寄送发票(状态需要为处理中)
const sendBusinessInvoice = (params) => putAction(`/api/tax/admin/enterprise/invoice/sendInvoice`, params);
// 发送
const sendInvoice = (params) => postAction('/api/tax/admin/enterprise/invoice/trade/sendInvoice', params);
// 物流信息
const updateApplyInvoiceLogistics = (params) => postAction('/api/tax/admin/enterprise/invoice/trade/updateApplyInvoiceLogistics', params);


const updateApplyInvoiceStatus = (params) => postAction('/api/tax/admin/enterprise/invoice/trade/updateApplyInvoiceStatus', params);
// 上传发票
const uploadInvoicePicture = (params) => postAction('/api/tax/admin/enterprise/invoice/trade/uploadInvoicePicture', params);
//申请发票列表
const getChanneConfirmInvoiceList = (params) => getAction('/slash-youth/channel/task/confirmInvoiceList', params);
// 添加企业充值信息
const addEnterpriseWalletRecharge = (params) => putAction('/api/tax/sys/enterprise/wallet/recharge/addEnterpriseWalletRecharge', params);
// 审核充值信息
const examineRecharge = (params)=>putAction(`/api/tax/sys/enterprise/wallet/recharge/examineRecharge`, params);
// 导出财务单
const exportBbExcel = function downFile(parameter){
  return axios({
    url: '/api/tax/sys/enterprise/wallet/recharge/exportWalletRecharge',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}
// 获取企业充值列表  查询渠道企业充值列表
// const getRechargeList = (params)=>getAction(`/api/tax/sys/enterprise/wallet/recharge/list`, params);//管理平台
const getRechargeList = (params)=>getAction(`/api/tax/sys/enterprise/wallet/recharge/channe/list`, params);//渠道管理
// 获取企业充值金额与笔数
const rechargeStatistics = (params) => getAction('/api/tax/sys/enterprise/wallet/recharge/rechargeStatistics', params);

//渠道商发布任务
const channePushTask = (params) => postAction('/slash-youth/channel/task/pushTask', params);

//确定付款名单
const channelConfirmPayList = (params) => postAction('/slash-youth/channel/task/confirmPayList', params);
//确定用工人员
const channelConfirmJoin = (params) => postAction('/slash-youth/channel/task/confirmJoin', params);

//获取批量上传工作者统计
const importWorkerStatisticsChannel = (params) => getAction('/slash-youth/channel/task/importWorkerStatistics/'+ params);


//付款管理明细
const getChannelPayList = (params) => getAction('/slash-youth/channel/task/getPayList', params);

//获取交易订单企业余额
const getEnterpriseBalanceChannel = (params) => getAction('api/tax/client/user/wallet/getEnterpriseBalance/'+ params);

//代理统一上传任务结果
const batchUploadResultChannel = (params) => postAction('/slash-youth/channel/task/batchUploadResult', params);


//代理单个上传任务结果
const uploadResultChannel = (params) => postAction('/slash-youth/channel/task/uploadResult', params);


//通知确认
const notifyConfirm = (params) => postAction('/slash-youth/channel/task/notifyConfirm', params);
//查询任务工作结果
const getResultChannel = (params) => getAction('/slash-youth/channel/task/getResult/'+ params);
//确定付款名单
const getNoRegisterNumChanner = (params) => getAction('/slash-youth/channel/task/getNoRegisterNum/'+ params);
//发布任务  根据企业id查询任务类别  
const getTaskType3 = (params) => getAction('/api/tax/client/enterprise/trade/task/getTaskType3/'+ params);

//查询企业开票信息
const getInvoiceInfoByEnterpriseIdChannel = (params) => getAction('/api/tax/client/invoice/getInvoiceInfoByEnterpriseId/' + params);



//渠道企业费用配置-添加
const channelFeeAdd = (params) => postAction('/api/tax/admin/enterprise/channel/fee/add', params);


//批量通知确认

const batchNotifyConfirm = (params) => postAction('/slash-youth/channel/task/batchNotifyConfirm', params);
//查询补单详情
const queryEnterpriseTradeSupplementDetaileds = (params) => getAction('/api/tax/client/enterprise/trade/queryEnterpriseTradeSupplementDetaileds' , params);

//查询补缴金额
const getSupplementAmount = (params) => getAction('/api/tax/client/enterprise/trade/getSupplementAmount/'+ params);



export {
  getDefaultInvoiceMailed,
  isWhiteList,
  enterpriseUnifyAdd,
  enterpriseUnifyAdd2,
  enterpriseAdd,
  getLineChart,
  notice,
  tradeCount,
  recharge,
  getInvoiceTradesAmount,
  getWalletByType,
  getPayStatistics,
  getEnterpriseReserveInfo,
  getUserLoginInfo,
  getFeePoint,
  tastEdit,
  fulFilTask,
  invoiceList,
  confirmInvoice,
  taskWorkerPayList,
  submitPeriodPay,
  cancelPay,
  taskWorkerPayItemList,
  addPeriodPay,
  getRechargesAccount,
  getRecharges,
  getBanknum,
  importWorkerStatistics,
  submitApply,
  queryImportWorkerList,
  getServiceRule,
  batchInvitationRecommendUser,
  getRoleType,
  getEnterpriseTradeDetaileds,
  getUserData,
  invitationRecommendUser,
  checkRepetition,
  getRandomRecommendUser,
  sendInvitationSms,
  resetPwd,
  invitation,
  getUserByPhone,
  getUserBindEmail,
  getInvoiceByIndustryId,
  getApplyInvoiceTradeDetails,
  getApplyInvoiceTradeList,
  getInvoiceMailed,
  submitTicket,
  ticketInfos,
  ticketList,
  taskPay,
  prePay,
  getBalance,
  taskWorkerPay,
  getOrderList,
  taskWorkerResult,
  getByWorkerIds,
  taskWorkerPaySubmit,
  getPayDetails,
  settlementStatistical,
  taskWorkerPayItem,
  getRaskTypeName,
  cancelAssign,
  refuseAssign,
  raskAssign,
  getWorkList,
  getRaskList,
  raskListAdd,
  getRaskType,
  raskCancel,
  raskDetail,
  raskEnd,
  checkBindWxMq,
  getPubFunAndItem,
  productionList,
  queryPermissionsByUser,
  //下面是企业管理
  getSysRegion, 
  getSysIndustry, 
  getEnterpriseList, 
  getStaffEnterpriseList, 
  addEnterpriseBaseInfo, 
  updateEnterpriseBaseInfo, 
  getEnterpriseBaseInfo, 
  examineEnterprise, 
  stopCooperate, 
  startCooperate, 
  getStaffList, 
  addEnterpriseStaff, 
  freezeEnterpriseStaff, 
  getAccountList, 
  freezeEnterpriseAccount, 
  resetAccountPassword, 
  getEnterpriseConfig, 
  updateEnterpriseConfig, 
  getEnterpriseScopeList, 
  getSysBusinessScopeList, 
  getBusinessScopeBox, 
  getSysBusinessScopeRate, 
  addEnterpriseBusinessScope, 
  freezeEnterpriseBusinessScope, 
  getEnterpriseContractList, 
  addEnterpriseContract, 
  addCompanyBankInfo, 
  updateCompanyBankInfo,
  updateCompanyIndentifyInfo, 
  getCompanyIndentifyInfo, 
  getCompanyBankInfo, 
  addRebateRules, 
  getRebateRules, 
  updataRebateRules, 
  getEnterpriseBankInfo, 
  initEnterpriseStaff, 
  unfreezeEnterpriseStaff, 
  getPreSignLists, 
  addUserSign, 
  authAddUserSign, 
  addSignUser, 
  saveEnterpriseInvoiceMailed, 
  getEnterpriseInvoiceMailed, 
  commissionQuery, 
  commissionUpdate,
  addEmailList,
  duplicateCheck,
  editEmailList,
  feeAdd,
  feeAudit,
  feeQuery,
  getAgentBox,
  getDlsInfo,
  getEmailList,
  getEmployee,
  taxEnterpriseServiceRuleItemDel,
  taxEnterpriseServiceRuleItemEdit,
  updateEnterpriseContractStatus,
  updateWithdrawalModel,
  uploadFile,
  getChanneList,
  getTaskChanneList,
  disposeWithdrawal,
  getEnterpriseDownBox,
  getEnterpriseTradeDetailedList,
  getEnterpriseTradeMainList,
  getTradeTaskTemplate,
  getTradeWithdrawal,
  getTradeWithdrawalDetail,
  getWalletWithdrawalBankReceipt,
  getqueryTradeTask,
  queryResult,
  queryTradeConfirm,
  taskType,
  tradeRefund,
  updateQsBank,
  updateRemark,
  updateTradeConfirm,
  updateTradeTask,
  getAgentEnterpriseTradeMainList,
  exportEnterpriseInvoiceTradesAmount,
  getEnterpriseInvoiceTradesAmountList,
  getInvoiceTradesAmountList,
  getChanneApplyInvoiceTradeList,
  getTaskResult,
  getTaskType,
  taskConfirmOrderList,
  taskQueryById,
  getRechargeChanneList,
  getUserLoginInfoNowAccount,
  getEnterprisePayConfig,
  exportReportInvoiceList,
  getApplyInvoiceDetails,
  getInvoicePicture,
  handleInvoiceApply,
  repealApply,
  sendBusinessInvoice,
  sendInvoice,
  updateApplyInvoiceLogistics,
  updateApplyInvoiceStatus,
  uploadInvoicePicture,
  getChanneConfirmInvoiceList,
  addEnterpriseWalletRecharge,
  examineRecharge,
  exportBbExcel,
  getRechargeList,
  rechargeStatistics,
  channePushTask,
  channelConfirmPayList,
  channelConfirmJoin,
  importWorkerStatisticsChannel,
  getChannelPayList,
  getEnterpriseBalanceChannel,
  batchUploadResultChannel,
  uploadResultChannel,
  notifyConfirm,
  getResultChannel,
  getNoRegisterNumChanner,
  getTaskType3,
  getInvoiceInfoByEnterpriseIdChannel,
  getInvoiceByIndustryId2,
  getInvoiceMailedByEnterpriseId,
  channelFeeAdd, 
  batchNotifyConfirm,
  queryEnterpriseTradeSupplementDetaileds,
  getSupplementAmount,
}